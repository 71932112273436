<template>
  <nav class="navbar is-fixed-top no-print" role="navigation" aria-label="dropdown navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img v-if="$auth.user().client && $auth.user().client.logo" :src="$auth.user().client.logo.url">
        <img v-else src="@/assets/world300.png" width="30" height="30" >
      </a>
      <a role="button" class="navbar-burger burger" v-on:click="toggleMobileMenu()" v-bind:class="{ 'is-active': isActive }" data-target="navMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navMenu" class="navbar-menu" v-bind:class="{ 'is-active': isActive }">
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable" v-if="$auth.check(['admin', 'admin-client'])">
          <a class="navbar-link">Airplane</a>
          <div class="navbar-dropdown is-boxed is-hidden-mobile">
            <span class="list-item-title">Fleet</span>
            <router-link to="/fleets" class="navbar-item" v-if="$auth.check(['admin', 'admin-client'])">List</router-link>
            <router-link to="/fleets/create" class="navbar-item" v-if="$auth.check(['admin'])">Create</router-link>
            <hr class="navbar-divider">
            <span class="list-item-title">Aircraft</span>
            <router-link to="/aircrafts" class="navbar-item">List</router-link>
            <router-link to="/aircrafts/create" class="navbar-item" v-if="$auth.check(['admin'])">Create</router-link>
          </div>
        </div> 
        <div class="navbar-item has-dropdown is-hoverable" v-if="$auth.check(['admin', 'admin-client'])">
          <a class="navbar-link">Users</a>
          <div class="navbar-dropdown is-boxed is-hidden-mobile">
            <span class="list-item-title">User</span>
            <router-link to="/users" class="navbar-item">List</router-link>
            <router-link to="/users/create" class="navbar-item">Create</router-link>
            <hr class="navbar-divider">
            <span class="list-item-title">Client</span>
            <router-link to="/clients" class="navbar-item">List</router-link>
            <router-link to="/clients/create" class="navbar-item" v-if="$auth.check(['admin'])">Create</router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Navigation</a>
          <div class="navbar-dropdown is-boxed is-hidden-mobile">
            <span class="list-item-title">Flight Plan</span>
            <router-link to="/plans" class="navbar-item" v-if="$auth.check(['admin', 'admin-client'])">List</router-link>
            <router-link to="/plans/create" class="navbar-item">Create</router-link>
            <hr class="navbar-divider">
            <span class="list-item-title">Route</span>
            <router-link to="/routes" class="navbar-item">List</router-link>
            <router-link to="/routes/create" class="navbar-item">Create</router-link>
            <hr class="navbar-divider">
            <span class="list-item-title">Waypoint</span>
            <router-link to="/waypoints" class="navbar-item">List</router-link>
            <router-link to="/waypoints/create" class="navbar-item">Create</router-link>
            <hr class="navbar-divider">
            <router-link to="/navdata-dbs" class="navbar-item"  v-if="$auth.check(['admin'])">Database</router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Flight Schedule</a>
          <div class="navbar-dropdown is-boxed is-hidden-mobile">
            <span class="list-item-title">Flight Schedule</span>
            <router-link to="/flight-schedule" class="navbar-item">List</router-link>
            <router-link to="/flight-schedule/define" v-if="$auth.check(['admin', 'admin-client'])" class="navbar-item">Define</router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">
            Welcome
            <span v-if="$auth.user().previous_login_at">&nbsp;back</span>,<strong>&nbsp;{{ $auth.user().name }}</strong>
            <span v-if="$auth.user().previous_login_at">
              , your last login was on <strong>&nbsp;{{ $auth.user().previous_login_at}}</strong>
            </span>
          </div>
          <div class="navbar-dropdown is-boxed is-hidden-mobile">
            <a class="navbar-item item-logout" v-on:click="onLogout()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    /*
     * Load async data
     */
    onLogout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
    toggleMobileMenu() {
      this.isActive = !this.isActive
    },
    toggleDropdowns() {
      const component = this;
      const navMenu = document.querySelector('#navMenu');
      const navLinks = navMenu.querySelectorAll('.navbar-link');
      navLinks.forEach(function(navLink) {
        const nextElement = navLink.nextElementSibling;
        navLink.addEventListener('click', function() {
          // mostrar/ocultar el menu al hacer 'CLICK'
          nextElement.classList.toggle('is-hidden-mobile');
        })
        const navItems = nextElement.querySelectorAll('.navbar-item:not(.item-logout)');
        navItems.forEach(function(navItem) {
          navItem.addEventListener('click', function() {
            // mostrar/ocultar el menu al hacer 'CLICK'
            nextElement.classList.toggle('is-hidden-mobile');
            // cerrar el 'Menu Principal' al hacer 'CLICK'
            component.toggleMobileMenu();
          })
        });
      });
    }
  },
  mounted() {
    this.toggleDropdowns();
  }
};
</script>
<style>
  @media print
  {
      .no-print, .no-print *
      {
          display: none !important;
      }
  }
  .list-item-title {
    font-weight: bold;
    padding-left: 0.8rem;
  }
</style>
