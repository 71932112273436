import Vue from 'vue';
import Vuex from 'vuex';
import scheduledFlights from "@/store/modules/scheduledFlights";
import planResults from "@/store/modules/planResult";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    scheduledFlights,
    planResults
  }
})