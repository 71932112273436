import scheduledFlight from '@/classes/scheduledFlight'

const getDefaultState = () => {
  return {
    scheduledFlights: [],
    validations: [],
  }
}

const state = getDefaultState()

const getters = {
  allInvalidScheduledFlights: state => state.scheduledFlights.filter(scheduled => {
    return !scheduled.isValid
  })
}

const actions = {
  resetScheduledFlights ({commit}) {
    commit('resetState')
  },
  addScheduledFlight({commit}, scheduled_flight) {
    let newScheduledFlight;
    if (!scheduled_flight) {
      newScheduledFlight = scheduledFlight.createEmpty()
    } else {
      newScheduledFlight = new scheduledFlight(scheduled_flight.etd, scheduled_flight.eta, scheduled_flight.route, scheduled_flight.flight_level, scheduled_flight.alternateAirports)
    }
    commit('newScheduleFlight', newScheduledFlight);
  },
  changeScheduledFlight({commit}, index, scheduled_flight) {
    commit('setScheduledFlight', {index, scheduled_flight});
  }
}

const mutations = {
  newScheduleFlight: (state, scheduledFlight) => state.scheduledFlights.push(scheduledFlight),
  resetState: (state) => Object.assign(state, getDefaultState())
}

export default {
  state,
  getters,
  actions,
  mutations
};