const defaults = [
    {
        path: '/',
        meta: {
            auth: true
        },
        component: () => import("@/components/Home.vue"),
    },
    {
        path: '/403',
        meta: {auth: true},
        component: () => import("@/components/403.vue"),
    },
    {
        path: '*',
        meta: {auth: true},
        component: () => import("@/components/404.vue"),
    }
];

const authRoutes = [
    {
        path: '/auth/login',
        name: 'login',
        meta: {auth: false},
        component: () => import("@/components/auth/Login.vue"),
    }, {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        meta: {auth: false},
        component: () => import("@/components/auth/ForgotPassword.vue"),
    }, {
        path: '/auth/password/reset/:token',
        name: 'password-reset',
        meta: {auth: false},
        component: () => import("@/components/auth/PasswordReset.vue"),
    }
];

const routeRoutes = [
    {
        path: '/routes',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/routes/RoutesIndex.vue"),
    }, {
        path: '/routes/create',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/routes/RoutesCreator.vue"),
    }, {
        path: '/routes/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/routes/RoutesCreator.vue"),
    }, {
        path: '/routes/:id',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/routes/RoutesShow.vue"),
    }
];

const userRoutes = [
    {
        path: '/users',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/users/UsersIndex.vue"),
    },
    {
        path: '/users/create',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/users/UsersCreator.vue"),
    }, {
        path: '/users/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/users/UsersCreator.vue"),
    }
];

const planRoutes = [
    {
        path: '/plans',
        meta: {
            auth: {
                roles: ['admin', 'user'],
            }
        },
        component: () => import("@/components/plans/PlansIndex.vue"),
    },
    {
        path: '/plans/create',
        meta: {
            auth: {
                roles: ['admin', 'user'],
            }
        },
        component: () => import("@/components/plans/PlanCreator.vue"),
    }, {
        path: '/plans/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'user'],
            }
        },
        component: () => import("@/components/plans/PlanEditor.vue"),
    }
];

const fleetRoutes = [
    {
        path: '/fleets',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/fleets/FleetsIndex.vue"),
    },
    {
        path: '/fleets/create',
        meta: {
            auth: {
                roles: ['admin'],
            }
        },
        component: () => import("@/components/fleets/FleetCreator.vue"),
    },
    {
        path: '/fleets/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/fleets/FleetCreator.vue"),
    }
];

const aircraftRoutes = [
    {
        path: '/aircrafts',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/aircrafts/AircraftIndex.vue"),
    },
    {
        path: '/aircrafts/create',
        meta: {
            auth: {
                roles: ['admin'],
            }
        },
        component: () => import("@/components/aircrafts/AircraftCreator.vue"),
    }, {
        path: '/aircrafts/:aircraftId/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/aircrafts/AircraftCreator.vue"),
    }
];

const clientRoutes = [
    {
        path: '/clients',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/clients/ClientIndex.vue"),
    },
    {
        path: '/clients/create',
        meta: {
            auth: {
                roles: ['admin'],
            }
        },
        component: () => import("@/components/clients/ClientCreator.vue"),
    }, {
        path: '/clients/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/clients/ClientCreator.vue"),
    }
];

const navdataDbRoutes = [
    {
        path: '/navdata-dbs',
        meta: {
            auth: {
                roles: ['admin'],
            }
        },
        component: () => import("@/components/navdata-dbs/NavdataDbIndex.vue"),
    }
];

const waypointRoutes = [
    {
        path: '/waypoints',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/waypoints/WaypointIndex.vue"),
    },
    {
        path: '/waypoints/create',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/waypoints/WaypointCreator.vue"),
    }, {
        path: '/waypoints/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/waypoints/WaypointCreator.vue"),
    }
];

const flightScheduleRoutes = [
    {
        path: '/flight-schedule',
        meta: {
            auth: {
                roles: ['admin', 'user', 'admin-client'],
            }
        },
        component: () => import("@/components/flight-schedule/FlightScheduleIndex.vue"),
    },
    {
        path: '/flight-schedule/define',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/flight-schedule/FlightScheduleCreator.vue"),
    }, {
        path: '/flight-schedule/:id/edit',
        meta: {
            auth: {
                roles: ['admin', 'admin-client'],
            }
        },
        component: () => import("@/components/flight-schedule/FlightScheduleCreator.vue"),
    }, {
        path: '/flight-schedule/:scheduledFlightId/create-plan',
        meta: {
            auth: {
                roles: ['admin', 'user'],
            }
        },
        component: () => import("@/components/plans/PlanCreator.vue"),
    },
];

const routeConfig = defaults.concat(
    authRoutes,
    routeRoutes,
    userRoutes,
    planRoutes,
    fleetRoutes,
    aircraftRoutes,
    clientRoutes,
    navdataDbRoutes,
    waypointRoutes,
    flightScheduleRoutes
);

export default {
    mode: 'history',
    routes: routeConfig
}