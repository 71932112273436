export default class scheduledFlight {
  etd;
  eta;
  route;
  route_id;
  flight_level;
  alternateAirports;

  constructor(etd, eta, route, flightLevel, alternateAirports) {
    this.etd = etd;
    this.eta = eta;
    this.route = route;
    this.route_id = route.id;
    this.flight_level = flightLevel;
    this.alternateAirports = alternateAirports;
  }

  static createEmpty() {
    return new scheduledFlight(null, null, {}, null, [{route: {}, route_id: null, flight_level: null}])
  }

  setFlightLevel(value) {
    if(isNaN(parseInt(value))) {
      this.flight_level = null;
      return;
    }
    this.flight_level = parseInt(value);
  }

  get routeIsValid() {
    return this.checkRouteValidity(this.route)
  }

  get flightLevelIsValid() {
    return this.checkFlightLevelValidity(this.flight_level);
  }

  checkRouteValidity(route) {
    return JSON.stringify(route) !== '{}'
  }
  checkFlightLevelValidity(flight_level) {
    return flight_level !== null && (flight_level > 0 && flight_level <= 1000);
  }

  get alternateAirportsAreValids() {
    return this.alternateAirports.reduce((prev, curr) => prev && this.alternateAirportIsValid(curr), true)
  }

  get isValid() {
    if (!this.routeIsValid) {
      console.log('no route in flight scheduled')
      return false;
    }

    if (!this.flightLevelIsValid) {
      console.log('invalid flight level in scheduledFlight')
      return false;
    }

    if(!this.alternateAirportsAreValids) {
      console.log('alternateAirports are not valid');
      return false;
    }

    console.log('undefined validation')
    return true;
  }

  alternateAirportIsValid(airport) {
    return this.checkRouteValidity(airport.route) && this.checkFlightLevelValidity(airport.flight_level);
  }
}