import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import VeeValidate from 'vee-validate';
import vueHeadful from 'vue-headful';
import VueMask from 'v-mask';
import Buefy from 'buefy';
import * as VueGoogleMaps from 'vue2-google-maps';
import underscore from 'vue-underscore';
import App from './App';
import routes from './routes';
import moment from 'moment';
import store from './store';

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VeeValidate, {
    validity: true,
    classes: true,
    classNames: {
        valid: 'is-success',
        invalid: 'is-danger'
    }
});

Vue.component('vue-headful', vueHeadful);
Vue.use(require('vue-moment'));
Vue.use(underscore);
Vue.use(VueMask);
Vue.use(Buefy, { defaultDateFormatter: (date) => moment(date).format('MM/DD/YYYY') })
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCnzTemm61iH1nRFBmdqlzuP7lYSSIok7E',
        libraries: 'places',
    },
})

Vue.router = new VueRouter(routes);
Vue.http.options.root = `${process.env.VUE_APP_HOST}`;
Vue.http.interceptors.push(function(request, next) {
    next(function(res) {
        // Unauthorized Access
        if (res.status === 401 && ['Token Expired', 'Wrong Token', 'Unauthorized'].indexOf(res.data.error) > -1) {
            Vue.auth.logout({
                redirect: {
                    name: 'login'
                }
            });
        }
    });
});
Vue.use(require('@websanova/vue-auth'), {
    auth: {
        request: function(req, token) {
            req.headers.set('Authorization', token);
            req.headers.set('Accept', 'application/json');
        },
        response: function(res) {
            if (res.data.token) {
                return res.data.token;
            }
        }
    },
    http: require('@websanova/vue-auth/drivers/http/vue-resource.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    authRedirect: { path: '/auth/login' },
    forbiddenRedirect: { path: '/403' },
    rolesVar: 'privileges'
});
App.router = Vue.router;

new Vue({
    store,
    render: h => h(App)
}).$mount('#app');