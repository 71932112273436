const getDefaultState = () => {
  return {
    result: {
      id: 0,
      nav_logs: []
    },
  }
}

const state = getDefaultState()

const getters = {
    hasResults: state => state.result.id > 0,
    resultId: (state, getters) => {
      if (getters.hasResults) {
        return state.result.id
      }
      return null;
    },
}

const actions = {
  resetResult ({commit}) {
    commit('resetState')
  },
  addResult({commit}, result) {
    commit('storeResult', result);
  }
}

const mutations = {
  storeResult: (state, result) => state.result = result,
  resetState: (state) => Object.assign(state, getDefaultState())
}

export default {
  state,
  getters,
  actions,
  mutations
};