<template>
  <!-- <div v-if="$auth.ready()">
    <nav-bar></nav-bar>
    <router-view></router-view>
  </div> -->
  <div class="is-fluid" v-if="layoutAppVisible">
    <section class="has-navbar-fixed-top">
      <nav-bar></nav-bar>
      <section class="backimage fullheight fullwidth">
        <div class="container" style="padding-top: 10px;">
          <router-view></router-view>
        </div>
      </section>
    </section>
  </div>
  <div v-else-if="layoutAuthVisible">
    <router-view></router-view>
  </div>
  <div v-else-if="!$auth.ready()">
    Loading ...
  </div>
</template>
<script>
import NavBar from "./components/Navbar.vue";
import Vue from 'vue'

var jwtDecode = require('jwt-decode');

export default {
  name: "app",
  components: {
    NavBar
  },
  computed: {
    layoutAppVisible() {
      return this.$auth.ready() && this.$auth.check();
    },
    layoutAuthVisible() {
      return this.$auth.ready() && !this.$auth.check();
    }
  },
  mounted() {
    // var _this = this;
    // Set up $auth.ready with other arbitrary loaders (ex: language file).
    // setTimeout(function () {
    //   _this.loaded = true;
    //   if (this.$auth && !this.$auth.check()) {
    //     this.$router.push('/auth/login');
    //   }
    // }, 500);
  },
  created() {
    this.$auth.ready(function () {
      // eslint-disable-next-line no-console
      // console.log(this.$router.this);
      // if (this.$auth && !this.$auth.check()) {
      //   this.$router.push('/auth/login');
      // }
      //Si falta menos de 20 minutos para que el token expire, se solicita un refresco.
      Vue.http.interceptors.push((req, next) => {
        next(() => {
          if (this.$auth.check()) {
            let jwt_decoded = jwtDecode(this.$auth.token());
            const current_time = new Date().getTime() / 1000;
            const tiempo_para_expirar = (jwt_decoded.exp - current_time);
            if (tiempo_para_expirar < 1200) {
              this.$auth.refresh();
            }
          }
        });
      });
    });
  }
};
</script>
<style lang="scss">
@import "./App.scss";
</style>
